import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Container from "../components/Container";

// markup
const NotFoundPage = () => {
  return (
    <Layout title="Uh oh! Page not found.">
      <Container flexGrow="1" textAlign="center">
        <h1>404! The page you're looking for doesn't exist.</h1>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
